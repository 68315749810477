define("discourse/plugins/discourse-docs/discourse/templates/docs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="docs">
    {{outlet}}
  </div>
  
  */
  {
    "id": "nuRN0COq",
    "block": "[[[10,0],[14,0,\"docs\"],[12],[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"div\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-docs/discourse/templates/docs.hbs",
    "isStrictMode": false
  });
});