import { template as template_9b053a140dd8471cbe73c42d91db311d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_9b053a140dd8471cbe73c42d91db311d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
