import { template as template_a4cd69132c5647eb836f28bc4e76c03f } from "@ember/template-compiler";
const SidebarSectionMessage = template_a4cd69132c5647eb836f28bc4e76c03f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
