import { template as template_00083ddfb34443cbbd5cfa2ba7de3588 } from "@ember/template-compiler";
const FKLabel = template_00083ddfb34443cbbd5cfa2ba7de3588(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
